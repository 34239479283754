var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table",attrs:{"id":"users"}},[_c('vue-good-table',{ref:"user-table",attrs:{"columns":_vm.columns,"rows":_vm.dataRows || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"sort-options":{
      enabled: false,
    },"style-class":"vgt-table"},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"font-weight-bold text-name"},[_vm._v(_vm._s(props.row.name))]),_c('br'),_c('small',{staticStyle:{"font-style":"italic"}},[_vm._v(_vm._s(props.row.Address))])]):(props.column.field === 'phone')?_c('span',[_c('span',{staticClass:"text-nowrap text-right"},[_vm._v(_vm._s(props.row.phone))]),_c('br'),_c('span',{staticClass:"text-nowrap text-right"},[_vm._v(_vm._s(props.row.email))])]):(props.column.field === 'content')?_c('span',[_c('span',{staticClass:"text-nowrap text-center"},[_vm._v(_vm._s(props.row.content))])]):(props.column.field === 'carCategoryName')?_c('span',[_c('span',{staticClass:"text-nowrap text-center"},[_vm._v(_vm._s(props.row.carCategoryName))])]):(props.column.field === 'groupId')?_c('span',[(props.row.groupId === 3)?_c('span',{staticClass:"text-nowrap text-center"},[_vm._v("Đăng ký lái thử")]):(props.row.groupId === 4)?_c('span',{staticClass:"text-nowrap text-center"},[_vm._v("Đăng ký lịch sửa chữa")]):(props.row.groupId === 1)?_c('span',{staticClass:"text-nowrap text-center"},[_vm._v("Yêu cầu tư vấn")]):(props.row.groupId === 2)?_c('span',{staticClass:"text-nowrap text-center"},[_vm._v("Thông tin")]):_vm._e()]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"current-page":_vm.urlQuery.currentPage,"total-items":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }